import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Carousel from "../carousel/Carousel";
import CarouselItem from "../carousel/CarouselItem";
import "./_carousel-data-input.scss"

class CarouselDataInput extends Component {

    state = {
        page: 0
    };

    onPageChange = page => {
        this.setState({
            page
        })
    };

    onClick(value) {
        this.props.input.onChange(value);
    }

    isSelected(value) {
        return this.props.input.value === value;
    }

    render() {
        const {data, label} = this.props;
        const {page} = this.state;
        return (
            <Carousel page={page} onPageChange={this.onPageChange} title={label}>
                {
                    data.map((item, key) =>
                        <CarouselItem key={key}>
                            {
                                item.map((d, key2) =>
                                    <div className={`data-item${this.isSelected(d.value) ? " selected" : ""}`}
                                         key={key2} onClick={() => this.onClick(d.value)}>
                                        {d.name}
                                    </div>
                                )
                            }
                        </CarouselItem>
                    )
                }
            </Carousel>
        );
    }
}

CarouselDataInput.propTypes = {
    data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.node
    }))).isRequired,
    label: PropTypes.string,
};

export default CarouselDataInput;