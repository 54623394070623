import React, {Component} from 'react';
import RfidHeader from "../rfid-header/RFIDHeader";
import {connect} from 'react-redux';
import "./_animal-chooser.scss"
import Keyboard from "../keyboard/Keyboard";
import Carousel from "../carousel/Carousel";
import CarouselItem from "../carousel/CarouselItem";
import Button from "../../../components/basics/button/Button";
import {Col, Row} from "react-bootstrap";
import groupsDB from "../../../database/groupsDB";
import {Link} from "react-router-dom";
import Drawer from "../drawer/Drawer";
import buildingsDB from "../../../database/buildingsDB";
import {SectorType} from "../../../constans/sectorTypes";
import animalsDB from "../../../database/animalsDB";
import {Level} from "../../../constans/levelTypes";
import {compose} from "redux";
import {withTranslation} from "react-i18next";

function mapStateToProps(state) {
    return {
        farm: state.location.farm
    };
}

class GroupChooser extends Component {

    state = {
        page: 0,
        showSearch: false,
        groups: [],
        filteredGroups: [],
        hasEmptyChambers: buildingsDB.getAllChambersForSTypeWithLocation(SectorType.PIGLET_HOUSE, this.props.farm)
            .filter(item => animalsDB.getAllAnimalsForLocation(item, Level.CHAMBER).length === 0).length > 0
    };

    constructor(props) {
        super(props);

        const {farm} = this.props;
        let groups = groupsDB.getAllGroups(farm).sort((a, b) => a.GrNo1.localeCompare(b.GrNo1, undefined, {numeric: true}));
        this.state = {
            ...this.state,
            groups,
            filteredGroups: groups
        }
    }

    componentDidMount() {
        const {location: {state}, history, farm} = this.props;
        if (!state) {
            history.push(`/${farm}/mobile`)
        }
    }

    renderGroups() {
        const {page, hasEmptyChambers} = this.state;
        const {location: {state}, farm, t} = this.props;
        const {eventType, status, groupsNextPath} = state;
        let slicedGroups = [];
        let filteredGroups = this.state.filteredGroups.slice(0);
        if (hasEmptyChambers) {
            filteredGroups.unshift({newGroup: true});
        }
        for (let i = 0; i < filteredGroups.length; i += 9) {
            slicedGroups.push(filteredGroups.slice(i, i + 9));
        }
        return (
            <Carousel page={page} onPageChange={this.changePage} title={t("rfidApp.chooseGroup") + ":"}>
                {
                    slicedGroups.map((groups, key) =>
                        <CarouselItem key={key}>
                            <ul>
                                {
                                    groups.map((group, key2) =>

                                        <li key={key2}>
                                            <Link className={"animal-item"} to={{
                                                pathname: group.newGroup ? `/${farm}/mobile/groupChooser/newGroup` : groupsNextPath || `/${farm}/mobile/events/${eventType}/${status}`,
                                                state: {
                                                    ...state,
                                                    group
                                                }
                                            }}>
                                                <div>{group.newGroup ? t("new") : group.GrNo1}</div>
                                            </Link>
                                        </li>
                                    )
                                }
                            </ul>
                        </CarouselItem>
                    )
                }
            </Carousel>
        )
    }

    changePage = page => {
        this.setState({
            page
        })
    }

    onSearchClick = () => {
        this.setState({
            showSearch: true
        })
    };

    hideSearch = () => {
        this.setState({
            showSearch: false
        })
    };

    onKeyboardChange = search => {
        let groups = this.state.groups.slice(0);
        if (search) {
            groups = groups.filter(item => item.GrNo1.includes(search));
        }
        this.setState({
            filteredGroups: groups,
            page: 0
        })
    };

    render() {
        const {location: {state}} = this.props;
        if (!state) return null;
        const {breadcrumbs} = state;
        const {showSearch} = this.state;
        return (
            <div className={`chooser`}>
                <div className={`h-100 d-flex flex-column ${showSearch ? " no-pointer-events" : ""}`}>
                    <RfidHeader breadcrumbs={[...breadcrumbs.map(item => ({
                        ...item,
                        link: {...item.link, state}
                    })), {name: "Grupa"}]} back/>
                    <Row className="button-container">
                        <Col xs={2}>
                            <Button buttonStyle={"text"} onClick={this.onSearchClick}>
                                <span className="fa-stack">
                                    <i className="far fa-circle fa-stack-2x"/>
                                    <i className="far fa-search fa-stack-1x"/>
                                </span>
                            </Button>
                        </Col>
                    </Row>
                    {this.renderGroups()}
                </div>
                <Drawer onCloseClick={this.hideSearch} show={showSearch}>
                    <Keyboard onChange={this.onKeyboardChange}/>
                </Drawer>
            </div>
        );
    }
}

export default compose(
    connect(mapStateToProps),
    withTranslation()
)(GroupChooser);