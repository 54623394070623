import React, {Component} from 'react';
import "./_checker-container.scss"
import PropTypes from "prop-types";
import {Col, Row} from "react-bootstrap";

class CheckerContainer extends Component {
    render() {
        const {data} = this.props;
        return (
            <div className="checker-container">
                {
                    data.map((d, index) =>
                        <Row className="justify-content-center w-100" key={index}>
                            <Col xs={6}>
                                {d.label}:
                            </Col>
                            <Col xs={6} className={d.valueClassName}>
                                {d.value}
                            </Col>
                        </Row>
                    )
                }
            </div>
        );
    }
}

CheckerContainer.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.node.isRequired,
        valueClassName: PropTypes.string,
    })).isRequired,
};

export default CheckerContainer;