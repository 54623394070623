import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Button from "../../../components/basics/button/Button";
import CarouselItem from "./CarouselItem";
import "./_carousel.scss"
import _ from "lodash";
import Hammer from "hammerjs";

class Carousel extends Component {

    state = {
        maxPage: Carousel.getMaxPage(this.props.children)
    };

    carousel = React.createRef();

    static getMaxPage(children) {
        let maxPage = React.Children.count((Array.isArray(children) ? children : [children]).filter(item => item)) - 1;
        return maxPage < 0 ? 0 : maxPage;
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            maxPage: Carousel.getMaxPage(nextProps.children)
        }
    }

    componentDidMount() {
        let hammer = new Hammer(this.carousel.current);
        hammer.on("swipeleft", (e) => {
            const {page} = this.props;
            const {maxPage} = this.state;
            if (page !== maxPage) {
                this.onPageChange(page + 1);
            }
        })
        hammer.on("swiperight", (e) => {
            const {page} = this.props;
            if (page !== 0) {
                this.onPageChange(page - 1);
            }
        })
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (this.props.page !== nextProps.page) return true;
        if (!_.isEqual(this.props.children, nextProps.children)) return true;
        return !_.isEqual(this.state, nextState);
    }

    onPageChange(page) {
        this.props.onPageChange(page);
    }

    render() {
        const {page, title, children} = this.props;
        const {maxPage} = this.state;
        return (
            <div className="carousel" ref={this.carousel}>
                <div className="icon-container">
                    <Button buttonStyle={"text"} disabled={page === 0} onClick={() => this.onPageChange(page - 1)}
                            type={"button"}>
                        <span className="fa-stack">
                            <i className="far fa-chevron-circle-left fa-stack-2x"/>
                        </span>
                    </Button>
                </div>
                <div className="carousel-container">
                    {
                        title && <div className="title">{title}</div>
                    }
                    <ul className="carousel-list">
                        {
                            React.Children.map((Array.isArray(children) ? children : [children]).filter(item => item), (child, index) => child && React.cloneElement(child, {
                                page,
                                key: index,
                                show: page === index
                            }, child.props.children))
                        }
                    </ul>
                </div>
                <div className="icon-container">
                    <Button buttonStyle={"text"} disabled={page === maxPage}
                            onClick={() => this.onPageChange(page + 1)} type={"button"}>
                        <span className="fa-stack">
                            <i className="far fa-chevron-circle-right fa-stack-2x"/>
                        </span>
                    </Button>
                </div>
            </div>
        );
    }
}

Carousel.propTypes = {
    page: PropTypes.number.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.instanceOf(CarouselItem)),
        PropTypes.instanceOf(CarouselItem)
    ]).isRequired,
    onPageChange: PropTypes.func.isRequired,
    title: PropTypes.node
};

export default Carousel;