import React, {Component} from 'react';
import RfidHeader from "../rfid-header/RFIDHeader";
import {connect} from 'react-redux';
import "./_animal-chooser.scss"
import animalsDB from "../../../database/animalsDB";
import Keyboard from "../keyboard/Keyboard";
import InfoBox from "../../../components/basics/info-box/InfoBox";
import Carousel from "../carousel/Carousel";
import CarouselItem from "../carousel/CarouselItem";
import Button from "../../../components/basics/button/Button";
import {Col, Row} from "react-bootstrap";
import Drawer from "../drawer/Drawer";
import {getLocationID} from "../../../utils/BuildingUtils";
import {enhancedComparer} from "../../../utils/TextUtils";
import {compose} from "redux";
import {withTranslation} from "react-i18next";

function mapStateToProps(state) {
    return {
        farm: state.location.farm
    };
}

class AnimalChooser extends Component {

    state = {
        page: 0,
        showSearch: false,
        animals: [],
        filteredAnimals: [],
        selectedAnimals: []
    };

    constructor(props) {
        super(props);

        const {farm, location: {state}} = this.props;
        let animalType = state ? state.animalType : undefined;
        let location = state ? state.location : undefined;
        let animals = [];
        if (Array.isArray(animalType)) {
            for (let type of animalType) {
                animals = [...animals, ...animalsDB.getAllAnimals(farm, type, false, false)]
            }
        } else {
            animals = animalsDB.getAllAnimals(farm, animalType, false, false);
        }
        if (location) {
            console.log(location);
            let id = getLocationID(location);
            animals = animals.filter(animal => {
                if (Array.isArray(animal.PlcmntID)) {
                    return !!animal.PlcmntID.find(plcmnt => plcmnt.PlcmntID === id)
                } else {
                    return animal.PlcmntID === id
                }
            });
        }
        animals.sort((a, b) => enhancedComparer(a.AnmNo1, b.AnmNo1, {numeric: true}));
        this.state = {
            ...this.state,
            animals,
            filteredAnimals: animals
        }
    }

    componentDidMount() {
        const {location: {state}, history, farm} = this.props;
        if (!state) {
            history.push(`/${farm}/mobile`)
        }
    }

    onAnimalClick(animal) {
        const {location: {state: {singleSelect}}} = this.props;
        if (singleSelect) {
            this.setState({
                selectedAnimals: [animal]
            })
        } else {
            let selectedAnimals = this.state.selectedAnimals.slice(0);
            if (selectedAnimals.includes(animal)) {
                selectedAnimals = selectedAnimals.filter(item => item.AnmID !== animal.AnmID);
            } else {
                selectedAnimals.push(animal);
            }
            this.setState({
                selectedAnimals
            })
        }
    }

    isSelected(animal) {
        return this.state.selectedAnimals.includes(animal);
    }

    renderAnimals() {
        const {location: {state: {singleSelect}}, t} = this.props;
        const {filteredAnimals, page} = this.state;
        let slicedAnimals = [];
        for (let i = 0; i < filteredAnimals.length; i += 9) {
            slicedAnimals.push(filteredAnimals.slice(i, i + 9));
        }
        if (slicedAnimals.length === 0) {
            return <InfoBox boxColor={"warning"}>Nieodnaleziono zwierząt spełniających podane kryteria</InfoBox>
        }
        return (
            <Carousel page={page} onPageChange={this.changePage}
                      title={singleSelect ? t("rfidApp.chooseAnimal") : t("rfidApp.chooseAnimals")}>
                {
                    slicedAnimals.map((animals, key) =>
                        <CarouselItem>
                            <ul>
                                {
                                    animals.map((animal, key2) =>
                                        <li key={key2} onClick={() => this.onAnimalClick(animal)}>
                                            <div className={`animal-item${this.isSelected(animal) ? " selected" : ""}`}>
                                                <div>{animal.AnmNo1}</div>
                                            </div>
                                        </li>
                                    )
                                }
                            </ul>
                        </CarouselItem>
                    )
                }
            </Carousel>
        )
    }

    changePage = page => {
        this.setState({
            page
        })
    }

    onSearchClick = () => {
        this.setState({
            showSearch: true
        })
    };

    hideSearch = () => {
        this.setState({
            showSearch: false
        })
    };

    onKeyboardChange = search => {
        let animals = this.state.animals.slice(0);
        if (search) {
            animals = animals.filter(item => item.AnmNo1 && item.AnmNo1.includes(search));
        }
        animals.sort((a, b) => enhancedComparer(a.AnmNo1, b.AnmNo1, {numeric: true}));
        this.setState({
            filteredAnimals: animals,
            page: 0
        })
    };

    onSaveClick = () => {
        const {location: {state}, history, farm} = this.props;
        const {eventType, status, animalScannerNextPath, insertAs, insertAsObject, additionalState} = state;
        const {selectedAnimals} = this.state;
        history.push({
            pathname: animalScannerNextPath || `/${farm}/mobile/events/${eventType}/${status}`,
            state: {
                ...state,
                ...additionalState,
                [insertAs || "animals"]: insertAsObject ? selectedAnimals[0] : selectedAnimals
            }
        })
    };

    render() {
        const {location: {state}} = this.props;
        if (!state) return null;
        const {breadcrumbs} = state;
        const {showSearch, selectedAnimals} = this.state;
        return (
            <div className={"chooser"}>
                <div className={`h-100 d-flex flex-column ${showSearch ? " no-pointer-events" : ""}`}>
                    <RfidHeader breadcrumbs={breadcrumbs} back/>
                    <Row className="button-container">
                        <Col xs={2}>
                            <Button buttonStyle={"text"} onClick={this.onSearchClick}>
                                <span className="fa-stack">
                                    <i className="far fa-circle fa-stack-2x"/>
                                    <i className="far fa-search fa-stack-1x"/>
                                </span>
                            </Button>
                        </Col>
                        <Col xs={2}>
                            <Button buttonStyle={"text"} disabled={selectedAnimals.length === 0}
                                    onClick={this.onSaveClick}>
                                <span className="fa-stack">
                                    <i className="far fa-check-circle fa-stack-2x"/>
                                </span>
                            </Button>
                        </Col>
                    </Row>
                    {this.renderAnimals()}
                </div>
                <Drawer show={showSearch} onCloseClick={this.hideSearch}>
                    <Keyboard onChange={this.onKeyboardChange}/>
                </Drawer>
            </div>
        );
    }
}

export default compose(
    connect(mapStateToProps),
    withTranslation()
)(AnimalChooser);