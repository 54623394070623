import React, {Component} from 'react';
import {connect} from 'react-redux';
import RfidHeader from "../rfid-header/RFIDHeader";
import {Col, Row} from "react-bootstrap";
import Button from "../../../components/basics/button/Button";
import "./_animal-chooser.scss"
import buildingsDB from "../../../database/buildingsDB";
import InfoBox from "../../../components/basics/info-box/InfoBox";
import Carousel from "../carousel/Carousel";
import CarouselItem from "../carousel/CarouselItem";
import {SectorType} from "../../../constans/sectorTypes";
import {Link} from "react-router-dom";
import Drawer from "../drawer/Drawer";
import {compose} from "redux";
import {withTranslation} from "react-i18next";

function mapStateToProps(state) {
    return {
        farm: state.location.farm
    };
}

class LocationChooser extends Component {

    state = {
        showSearch: false,
        chambers: [],
        page: 0,
        filteredChambers: []
    };

    search = React.createRef();

    constructor(props) {
        super(props);

        const {farm, location: {state}} = this.props;
        if (state && state.locations) {
            this.state = {
                ...this.state,
                chambers: state.locations,
                filteredChambers: state.locations
            };
            return;
        }
        let buildings = buildingsDB.getAllBuildingsForFarm(farm);
        let array = [];
        for (let building of buildings) {
            for (let sector of building.Sectors) {
                for (let chamber of sector.Chambers) {
                    array.push({
                        name: chamber.CName,
                        value: chamber,
                        type: sector.SType
                    });
                }
            }
        }
        array.sort((a, b) => a.type - b.type);
        let filtered = array.slice(0);
        if (state) {
            const {sectorType, standsChooser, sectorTypes} = state;
            if (sectorType !== undefined) {
                filtered = filtered.filter(item => item.type === sectorType);
            }
            if (sectorTypes) {
                filtered = filtered.filter(item => sectorTypes.includes(item.type));
            }
            if (standsChooser) {
                filtered = filtered.filter(item => item.value.Boxes);
            }
        }
        this.state = {
            ...this.state,
            chambers: array,
            filteredChambers: filtered
        }
    }

    componentDidMount() {
        const {location: {state}, history, farm} = this.props;
        if (!state) {
            history.push(`/${farm}/mobile`)
        }
    }

    onPageChange = page => {
        this.setState({
            page
        })
    };

    getClassName(sType) {
        switch (sType) {
            case SectorType.DELIVERY:
                return "birth";
            case SectorType.MATING:
                return "mating";
            case SectorType.SOWS:
                return "sows";
            case SectorType.PIGLET_HOUSE:
                return "piglets";
            default:
                return "other"
        }
    }

    renderCarousel() {
        const {filteredChambers, page} = this.state;
        const {farm, location: {state}, t} = this.props;
        let slicedChambers = [];
        for (let i = 0; i < filteredChambers.length; i += 6) {
            slicedChambers.push(filteredChambers.slice(i, i + 6));
        }
        if (slicedChambers.length === 0) return <InfoBox boxColor={"warning"}>Brak komór</InfoBox>;
        console.log(state.standChooser);
        return (
            <Carousel page={page} onPageChange={this.onPageChange} title={t("rfidApp.chooseChamber") + ":"}>
                {
                    slicedChambers.map((chambers, key) =>
                        <CarouselItem key={key}>
                            <div className="building-list">
                                {
                                    chambers.map((chamber, key2) =>
                                        <Link to={{
                                            pathname: state.standsChooser ? `/${farm}/mobile/standsChooser/${chamber.value.CID}` : (state.locationNextPath || `/${farm}/mobile/events/${state.eventType}/${state.status}`),
                                            state: {
                                                ...state,
                                                [state.saveName || "location"]: chamber.value
                                            }
                                        }} className={`building-item ${this.getClassName(chamber.type)}`}
                                              key={key2}><span>{chamber.name}</span></Link>
                                    )
                                }
                            </div>
                        </CarouselItem>
                    )
                }
            </Carousel>
        )
    }

    onSearchClick = () => {
        this.setState({
            showSearch: true
        })
    };

    hideSearch = () => {
        this.setState({
            showSearch: false
        })
    };

    onFilterClick(type) {
        const {chambers} = this.state;
        let filtered = type === undefined ?
            chambers.filter(item => ![SectorType.DELIVERY, SectorType.MATING, SectorType.SOWS, SectorType.PIGLET_HOUSE].includes(item.type))
            : chambers.filter(item => item.type === type);
        const {standsChooser} = this.props.location.state;
        if (standsChooser) {
            filtered = filtered.filter(item => item.value.Boxes);
        }
        this.setState({
            filteredChambers: filtered,
            page: 0
        })
    }

    render() {
        const {location: {state}, t} = this.props;
        if (!state) return null;
        const {breadcrumbs} = state;
        const {showSearch} = this.state;
        return (
            <div className={`chooser`}>
                <div className={`h-100 d-flex flex-column ${showSearch ? " no-pointer-events" : ""}`}>
                    <RfidHeader breadcrumbs={breadcrumbs} back/>
                    <Row className="button-container">
                        <Col xs={2}>
                            <Button buttonStyle={"text"} onClick={this.onSearchClick}>
                                <span className="fa-stack">
                                    <i className="far fa-circle fa-stack-2x"/>
                                    <i className="far fa-search fa-stack-1x"/>
                                </span>
                            </Button>
                        </Col>
                    </Row>
                    {this.renderCarousel()}
                </div>
                <Drawer onCloseClick={this.hideSearch} show={showSearch}>
                    <div className="building-list">
                        <div className="building-item birth"
                             onClick={() => this.onFilterClick(SectorType.DELIVERY)}>
                            {t("SType.3")}
                        </div>
                        <div className="building-item mating"
                             onClick={() => this.onFilterClick(SectorType.MATING)}>
                            {t("SType.0")}
                        </div>
                        <div className="building-item sows"
                             onClick={() => this.onFilterClick(SectorType.SOWS)}>
                            {t("SType.1")}
                        </div>
                        <div className="building-item piglets"
                             onClick={() => this.onFilterClick(SectorType.PIGLET_HOUSE)}>
                            {t("SType.6")}
                        </div>
                        <div className="building-item other"
                             onClick={() => this.onFilterClick()}>
                            {t("modals.feedingExtraFeedingModal.other")}
                        </div>
                    </div>
                </Drawer>
            </div>
        );
    }
}

export default compose(
    connect(mapStateToProps),
    withTranslation()
)(LocationChooser);