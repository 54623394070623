import React, {Component} from 'react';
import {connect} from 'react-redux';
import RFIDHeader from "../rfid-header/RFIDHeader";
import {clearSubmitErrors, Field, reduxForm} from "redux-form";
import {Col, Row} from "react-bootstrap";
import Button from "../../../components/basics/button/Button";
import BigDataInput from "../data-input/BigDataInput";
import "../events/_event-status-view.scss"
import {submit} from "./NewGroupChooserSubmit"
import ErrorComponent from "../error/ErrorComponent";
import {compose} from "redux";
import {withTranslation} from "react-i18next";

function mapStateToProps(state) {
    return {
        farm: state.location.farm
    };
}

const formName = "newGroup";

class NewGroupChooser extends Component {

    constructor(props) {
        super(props);
        const {location: {state}, history, farm} = this.props;
        if (!state) {
            history.push(`/${farm}/mobile`)
        } else {
            this.props.initialize({
                GrNo1: 0
            })
        }
    }

    onCloseClick = () => {
        this.props.dispatch(clearSubmitErrors(formName))
    }

    render() {
        const {location: {state}, handleSubmit, error, submitFailed, submitting, t} = this.props;
        if (!state) return null;
        const {breadcrumbs} = state;
        return (
            <div className="event-status-view">
                <RFIDHeader breadcrumbs={[...breadcrumbs.map(item => ({
                    ...item,
                    link: {...item.link, state}
                })), {name: "Grupa"}]} back/>
                <form onSubmit={handleSubmit}>
                    <div className="submit">
                        <Button buttonStyle={"text"}>
                            <span className="fa-stack">
                                <i className="far fa-circle fa-stack-2x"/>
                                <i className="far fa-chevron-right fa-stack-1x"/>
                            </span>
                        </Button>
                    </div>
                    <Row className="justify-content-center h-100">
                        <Col xs={8}>
                            <Field
                                name="GrNo1"
                                component={BigDataInput}
                                label={t("rfidApp.newGroupNumber") + ":"}
                                columns={5}
                            />
                        </Col>
                    </Row>
                    <ErrorComponent onCloseClick={this.onCloseClick} error={error} show={submitFailed && !submitting}/>
                </form>
            </div>
        );
    }
}

NewGroupChooser = reduxForm({
    form: formName,
    onSubmit: submit
})(NewGroupChooser);

export default compose(
    connect(mapStateToProps),
    withTranslation()
)(NewGroupChooser);