import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Route, Switch} from "react-router-dom";
import MobileRFIDScanner from "./main-view/MobileRFIDScanner";
import RFIDParturition from "./events/parturition/RFIDParturition";
import RFIDAnimalScanner from "./rfid-scanners/RFIDAnimalScanner";
import "./_rfid-scanner-main-view.scss"
import AnimalChooser from "./choosers/AnimalChooser";
import RFIDParturitionStart from "./events/parturition/start/RFIDParturitionStart";
import RFIDParturitionStatus from "./events/parturition/status/RFIDParturitionStatus";
import RFIDParturitionEnd from "./events/parturition/end/RFIDParturitionEnd";
import RFIDInsemination from "./events/insemination/RFIDInsemination";
import RFIDInseminationEnd from "./events/insemination/RFIDInseminationEnd";
import RFIDHeatToInsemination from "./events/heat/RFIDHeatToInsemination";
import RFIDHeatExamination from "./events/heat/RFIDHeatExamination";
import RFIDCheckInsemination from "./events/insemination/check/RFIDCheckInsemination";
import RfidInseminationEndInfo from "./events/insemination/end-info/RFIDInseminationEndInfo";
import RfidUsg from "./events/usg/RFIDUsg";
import RfidUsgResult from "./events/usg/RFIDUsgResult";
import RfidUsgCheck from "./events/usg/check/RFIDUsgCheck";
import RfidUsgResultInfo from "./events/usg/result-info/RFIDUsgResultInfo";
import RfidUsgRepeat from "./events/usg/repeat/RFIDUsgRepeat";
import RfidNoPregnancy from "./events/no-pregnancy/RFIDNoPregnancy";
import RfidNoPregnancyEnd from "./events/no-pregnancy/RFIDNoPregnancyEnd";
import RFIDLocationScanner from "./rfid-scanners/RFIDLocationScanner";
import LocationChooser from "./choosers/LocationChooser";
import StandsChooser from "./choosers/StandsChooser";
import RFIDSeparationToPiglethouseAmount from "./events/separation-to-piglethouse/RFIDSeparationToPiglethouseAmountLocation";
import RFIDGroupScanner from "./rfid-scanners/RFIDGroupScanner";
import GroupChooser from "./choosers/GroupChooser";
import NewGroupChooser from "./choosers/NewGroupChooser";
import RFIDMommyMainView from "./events/mommy/RFIDMommyMainView";
import RFIDMommyGet from "./events/mommy/mommy-get/RFIDMommyGet";
import RFIDMommyAdd from "./events/mommy/mommy-add/RFIDMommyAdd";
import RFIDGraftingMainView from "./events/grafting/RFIDGraftingMainView";
import RFIDGraftingMainViewDosatron from "./events/grafting/RFIDGraftingMainViewDosatron";
import RFIDGraftingMainViewVaccine from "./events/grafting/RFIDGraftingMainViewVaccine";
import MedicineChooser from "./choosers/MedicineChooser";
import RFIDGraftingMainViewForage from "./events/grafting/RFIDGraftingMainViewForage";
import RFIDGraftingStart from "./events/grafting/grafting-add/RFIDGraftingStart";
import RFIDFallPiglets from "./events/fall-piglets/RFIDFallPiglets";
import RFIDFallPigletsEnd from "./events/fall-piglets/RFIDFallPigletsEnd";
import RFIDFall from "./events/fall/RFIDFall";
import RFIDFallEnd from "./events/fall/RFIDFallEnd";
import RFIDSelection from "./events/selection/RFIDSelection";
import RFIDSelectionEnd from "./events/selection/RFIDSelectionEnd";
import RFIDWeighting from "./events/weighting/RFIDWeighting";
import RFIDWeightingEnd from "./events/weighting/RFIDWeightingEnd";
import RFIDActiveNipplesAdd from "./events/active-nipples/RFIDActiveNipplesAdd";
import RFIDGraftingPigletsMainView from "./events/grafting-piglets/RFIDGraftingPigletsMainView";
import RFIDGraftingPigletsMainViewVaccine from "./events/grafting-piglets/RFIDGraftingPigletsMainViewVaccine";
import RFIDGraftingPigletsMainViewForage from "./events/grafting-piglets/RFIDGraftingPigletsMainViewForage";
import RFIDGraftingPigletsMainViewCastration from "./events/grafting-piglets/RFIDGraftingPigletsMainViewCastration";
import RFIDGraftingPigletsCastration from "./events/grafting-piglets/castration-add/RFIDGraftingPigletsCastration";
import RFIDGraftingPigletsStart from "./events/grafting-piglets/grafting-add/RFIDGraftingPigletsStart";
import RFIDTransferMainView from "./events/transfer/RFIDTransferMainView";
import RFIDTransferStand from "./events/transfer/RFIDTransferStand";
import RFIDTransferChamber from "./events/transfer/RFIDTransferChamber";
import RFIDSeparationToPiglethouseLocationChooser
    from "./events/separation-to-piglethouse/RFIDSeparationToPiglethouseLocationChooser";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import NewAnimalNumberChooser from "./choosers/NewAnimalNumberChooser";
import RFIDSellMainView from "./events/sell/RFIDSellMainView";
import RFIDSellAnimal from "./events/sell/animal/RFIDSellAnimal";
import RFIDSellLocation from "./events/sell/location/RFIDSellLocation";
import AnimalInformation from "./information/AnimalInformation";
import RFIDGraftingEnd from "./events/grafting/graftingEnd/RFIDGraftingEnd";
import RFIDGraftingUnfinished from "./events/grafting/grafting-found-started/RFIDGraftingUnfinished";
import RFIDInsertionMainView from "./events/insertion/insertion-main-view/RFIDInsertionMainView";
import RFIDInsertionLastView from "./events/insertion/insertion-last-view/RFIDInsertionLastView";
import RFIDReclassifyAdd from "./events/reclassify/RFIDReclassifyAdd";
import RFIDTreatmentMedicine from "./events/treatment/RFIDTreatmentMedicine";
import RFIDTreatmentAdd from "./events/treatment/RFIDTreatmentAdd";
import RFIDHeatMainView from "./events/heat/RFIDHeatMainView";
import isOnline from "is-online";
import RFIDHistory from "./history/RFIDHistory";
import RFIDSearch from "./events/search/RFIDSearch";
import RFIDSearchEnd from "./events/search/RFIDSearchEnd";
import RFIDChangeRFID from "./events/change-rfid/RFIDChangeRFID";
import LocationInformation from "./location-information/LocationInformation";
import Button from "../../components/basics/button/Button";
import queryString from "query-string";
import RfidCheckGrafting from "./events/check-grafting/RFIDCheckGrafting";
import RFIDTreatmentReason from "./events/treatment/RFIDTreatmentReason";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import RFIDSeparationToPiglethouseMainView
    from "./events/separation-to-piglethouse/RFIDSeparationToPiglethouseMainView";
import RFIDSeparationToPigletHouseAmountSingle
    from "./events/separation-to-piglethouse/RFIDSeparationToPigletHouseAmountSingle";

function mapStateToProps(state) {
    return {
        farm: state.location.farm
    };
}

class RfidScannerMainView extends Component {

    state = {
        showFullScreen: process.env.REACT_APP_STAGE === "local"
    }

    interval = null;

    constructor(props) {
        super(props);
        const {dispatch, location} = this.props;
        let queryParams = queryString.parse(location.search);
        dispatch({
            type: "HIDE_SIDE_NAVIGATION"
        });
        dispatch({
            type: "MOBILE_SET_SHOW_BREEDING",
            payload: queryParams.breeding === "true"
        })
    }

    async registerSync() {
        try {
            let registration = await navigator.serviceWorker.ready;
            await registration.sync.register("fetura-sync");
        } catch (e) {
            console.error("Error while registering background sync", e);
        }
    }

    async componentDidMount() {
        let body = document.getElementsByTagName("body")[0];
        body.classList.add("rfid-app");

        try {
            document.addEventListener("fullscreenchange", this.fullScreenChangeListener);
            await document.documentElement.requestFullscreen();
            await screen.orientation.lock("landscape");
        } catch (e) {
            console.error(e);
        }

        let online = await isOnline();
        if (!online) {
            this.registerSync();
        }
        this.interval = setInterval(async () => {
            let online = await isOnline();
            if (!online) {
                this.registerSync();
            }
        }, 10000);
    }

    async componentWillUnmount() {
        clearInterval(this.interval);
        try {
            document.removeEventListener("fullscreenchange", this.fullScreenChangeListener);
            await document.exitFullscreen();
            await screen.orientation.unlock();
        } catch (e) {
            console.error(e);
        }
    }

    fullScreenChangeListener = () => {
        this.setState({
            showFullScreen: !!document.fullscreenElement
        })
    }

    onEnableFullScreenClick = async () => {
        try {
            await document.documentElement.requestFullscreen();
            await screen.orientation.lock("landscape");
        } catch (e) {
            console.error(e);
        }
    }

    render() {
        const {farm, location, t} = this.props;
        const {showFullScreen} = this.state;
        return (
            <>
                <TransitionGroup className={"rfid-scanner-main-view" + (!showFullScreen ? " blur" : "")}>
                    <CSSTransition
                        key={location.key}
                        classNames="fade"
                        timeout={300}
                    >
                        <Switch location={location}>
                            <Route path={`/${farm}/mobile/events/parturition`}
                                   render={props => <RFIDParturition {...props}/>}
                                   exact/>
                            <Route path={`/${farm}/mobile/events/parturition/start`}
                                   render={props => <RFIDParturitionStart {...props}/>} exact/>
                            <Route path={`/${farm}/mobile/events/parturition/status`}
                                   render={props => <RFIDParturitionStatus {...props}/>} exact/>
                            <Route path={`/${farm}/mobile/events/parturition/end`}
                                   render={props => <RFIDParturitionEnd {...props}/>} exact/>
                            <Route path={`/${farm}/mobile/events/insemination`}
                                   render={props => <RFIDInsemination {...props}/>}
                                   exact/>
                            <Route path={`/${farm}/mobile/events/insemination/status`}
                                   render={props => <RFIDCheckInsemination {...props}/>} exact/>
                            <Route path={`/${farm}/mobile/events/insemination/end`}
                                   render={props => <RFIDInseminationEnd {...props}/>} exact/>
                            <Route path={`/${farm}/mobile/events/insemination/end/info`}
                                   render={props => <RfidInseminationEndInfo {...props}/>} exact/>
                            <Route path={`/${farm}/mobile/events/usg`}
                                   render={props => <RfidUsg {...props}/>} exact/>
                            <Route path={`/${farm}/mobile/events/usg/result`}
                                   render={props => <RfidUsgResult {...props}/>} exact/>
                            <Route path={`/${farm}/mobile/events/usg/check`}
                                   render={props => <RfidUsgCheck {...props}/>} exact/>
                            <Route path={`/${farm}/mobile/events/usg/result/:status`}
                                   render={props => <RfidUsgResultInfo {...props}/>} exact/>
                            <Route path={`/${farm}/mobile/events/usg/repeat`}
                                   render={props => <RfidUsgRepeat {...props}/>} exact/>
                            <Route path={`/${farm}/mobile/events/noPregnancy`}
                                   render={props => <RfidNoPregnancy {...props}/>} exact/>
                            <Route path={`/${farm}/mobile/events/noPregnancy/:reasonID`}
                                   render={props => <RfidNoPregnancyEnd {...props}/>} exact/>
                            <Route path={`/${farm}/mobile/events/separationToPigletHouse`}
                                   render={props => <RFIDSeparationToPiglethouseMainView {...props}/>} exact/>
                            <Route path={`/${farm}/mobile/events/separationToPigletHouse/location`}
                                   render={props => <RFIDSeparationToPiglethouseLocationChooser {...props}/>} exact/>
                            <Route path={`/${farm}/mobile/events/separationToPigletHouse/single`}
                                   render={props => <RFIDSeparationToPigletHouseAmountSingle {...props}/>} exact/>
                            <Route path={`/${farm}/mobile/events/separationToPigletHouse/chamber`}
                                   render={props => <RFIDSeparationToPiglethouseAmount {...props}/>} exact/>
                            <Route path={`/${farm}/mobile/events/mommy`}
                                   render={props => <RFIDMommyMainView {...props}/>} exact/>
                            <Route path={`/${farm}/mobile/events/mommy/get`}
                                   render={props => <RFIDMommyGet {...props}/>} exact/>
                            <Route path={`/${farm}/mobile/events/mommy/add`}
                                   render={props => <RFIDMommyAdd {...props}/>} exact/>
                            <Route path={`/${farm}/mobile/events/grafting`}
                                   render={props => <RFIDGraftingMainView {...props}/>} exact/>
                            <Route path={`/${farm}/mobile/events/grafting/dosatron`}
                                   render={props => <RFIDGraftingMainViewDosatron {...props}/>} exact/>
                            <Route path={`/${farm}/mobile/events/grafting/vaccine`}
                                   render={props => <RFIDGraftingMainViewVaccine {...props}/>} exact/>
                            <Route path={`/${farm}/mobile/events/grafting/forage`}
                                   render={props => <RFIDGraftingMainViewForage {...props}/>} exact/>
                            <Route path={`/${farm}/mobile/events/grafting/:medicineType/add`}
                                   render={props => <RFIDGraftingStart {...props}/>} exact/>
                            <Route path={`/${farm}/mobile/events/grafting/:medicineType/end`}
                                   render={props => <RFIDGraftingEnd {...props}/>} exact/>
                            <Route path={`/${farm}/mobile/events/grafting/:medicineType/unfinished`}
                                   render={props => <RFIDGraftingUnfinished {...props}/>} exact/>
                            <Route path={`/${farm}/mobile/events/checkGrafting/animal`}
                                   render={props => <RfidCheckGrafting {...props}/>} exact/>
                            <Route path={`/${farm}/mobile/events/fallPiglets`}
                                   render={props => <RFIDFallPiglets {...props}/>} exact/>
                            <Route path={`/${farm}/mobile/events/fallPiglets/:reasonID`}
                                   render={props => <RFIDFallPigletsEnd {...props}/>} exact/>
                            <Route path={`/${farm}/mobile/events/fall`}
                                   render={props => <RFIDFall {...props}/>} exact/>
                            <Route path={`/${farm}/mobile/events/fall/:reasonID`}
                                   render={props => <RFIDFallEnd {...props}/>} exact/>
                            <Route path={`/${farm}/mobile/events/selection`}
                                   render={props => <RFIDSelection {...props}/>} exact/>
                            <Route path={`/${farm}/mobile/events/selection/:reasonID`}
                                   render={props => <RFIDSelectionEnd {...props}/>} exact/>
                            <Route path={`/${farm}/mobile/events/weighting`}
                                   render={props => <RFIDWeighting {...props}/>} exact/>
                            <Route path={`/${farm}/mobile/events/weighting/:reasonID`}
                                   render={props => <RFIDWeightingEnd {...props}/>} exact/>
                            <Route path={`/${farm}/mobile/events/activeNipples/add`}
                                   render={props => <RFIDActiveNipplesAdd {...props}/>} exact/>
                            <Route path={`/${farm}/mobile/events/graftingPiglets`}
                                   render={props => <RFIDGraftingPigletsMainView {...props}/>} exact/>
                            <Route path={`/${farm}/mobile/events/graftingPiglets/vaccine`}
                                   render={props => <RFIDGraftingPigletsMainViewVaccine {...props}/>} exact/>
                            <Route path={`/${farm}/mobile/events/graftingPiglets/forage`}
                                   render={props => <RFIDGraftingPigletsMainViewForage {...props}/>} exact/>
                            <Route path={`/${farm}/mobile/events/graftingPiglets/castration`}
                                   render={props => <RFIDGraftingPigletsMainViewCastration {...props}/>} exact/>
                            <Route path={`/${farm}/mobile/events/graftingPiglets/castration/add`}
                                   render={props => <RFIDGraftingPigletsCastration {...props}/>} exact/>
                            <Route path={`/${farm}/mobile/events/graftingPiglets/:medicineType/add`}
                                   render={props => <RFIDGraftingPigletsStart {...props}/>} exact/>
                            <Route path={`/${farm}/mobile/events/transfer`}
                                   render={props => <RFIDTransferMainView {...props}/>} exact/>
                            <Route path={`/${farm}/mobile/events/transfer/stand`}
                                   render={props => <RFIDTransferStand {...props}/>} exact/>
                            <Route path={`/${farm}/mobile/events/transfer/chamber`}
                                   render={props => <RFIDTransferChamber {...props}/>} exact/>
                            <Route path={`/${farm}/mobile/events/sell`}
                                   render={props => <RFIDSellMainView {...props}/>} exact/>
                            <Route path={`/${farm}/mobile/events/sell/animal`}
                                   render={props => <RFIDSellAnimal {...props}/>} exact/>
                            <Route path={`/${farm}/mobile/events/sell/location`}
                                   render={props => <RFIDSellLocation {...props}/>} exact/>
                            <Route path={`/${farm}/mobile/events/insertion`}
                                   render={props => <RFIDInsertionMainView {...props}/>} exact/>
                            <Route path={`/${farm}/mobile/events/insertion/:animalKind`}
                                   render={props => <RFIDInsertionLastView {...props}/>} exact/>
                            <Route path={`/${farm}/mobile/events/reclassify/add`}
                                   render={props => <RFIDReclassifyAdd {...props}/>} exact/>
                            <Route path={`/${farm}/mobile/events/treatment/medicine`}
                                   render={props => <RFIDTreatmentMedicine {...props}/>} exact/>
                            <Route path={`/${farm}/mobile/events/treatment/reason`}
                                   render={props => <RFIDTreatmentReason {...props}/>} exact/>
                            <Route path={`/${farm}/mobile/events/treatment/add`}
                                   render={props => <RFIDTreatmentAdd {...props}/>} exact/>
                            <Route path={`/${farm}/mobile/events/heat`}
                                   render={props => <RFIDHeatMainView {...props}/>} exact/>
                            <Route path={`/${farm}/mobile/events/heat/toInsemination`}
                                   render={props => <RFIDHeatToInsemination {...props}/>} exact/>
                            <Route path={`/${farm}/mobile/events/heat/heatExamination`}
                                   render={props => <RFIDHeatExamination {...props}/>} exact/>
                            <Route path={`/${farm}/mobile/events/search`}
                                   render={props => <RFIDSearch {...props}/>} exact/>
                            <Route path={`/${farm}/mobile/events/search/:type`}
                                   render={props => <RFIDSearchEnd {...props}/>} exact/>
                            <Route path={`/${farm}/mobile/events/change/rfid`}
                                   render={props => <RFIDChangeRFID {...props}/>} exact/>
                            <Route path={`/${farm}/mobile/scanner`} exact
                                   render={props => <RFIDAnimalScanner {...props}/>}/>
                            <Route path={`/${farm}/mobile/locationScanner`} exact
                                   render={props => <RFIDLocationScanner {...props}/>}/>
                            <Route path={`/${farm}/mobile/groupScanner`} exact
                                   render={props => <RFIDGroupScanner {...props}/>}/>
                            <Route path={`/${farm}/mobile/animalChooser`} exact
                                   render={props => <AnimalChooser {...props}/>}/>
                            <Route path={`/${farm}/mobile/locationChooser`} exact
                                   render={props => <LocationChooser {...props}/>}/>
                            <Route path={`/${farm}/mobile/groupChooser`} exact
                                   render={props => <GroupChooser {...props}/>}/>
                            <Route path={`/${farm}/mobile/groupChooser/newGroup`} exact
                                   render={props => <NewGroupChooser {...props}/>}/>
                            <Route path={`/${farm}/mobile/animalChooser/newAnimal`} exact
                                   render={props => <NewAnimalNumberChooser {...props}/>}/>
                            <Route path={`/${farm}/mobile/standsChooser/:CID`} exact
                                   render={props => <StandsChooser {...props}/>}/>
                            <Route path={`/${farm}/mobile/medicineChooser`} exact
                                   render={props => <MedicineChooser {...props}/>}/>
                            <Route path={`/${farm}/mobile/information/:AnmID`} exact
                                   render={props => <AnimalInformation {...props}/>}/>
                            <Route path={`/${farm}/mobile/locationInformation/:locationID`} exact
                                   render={props => <LocationInformation {...props}/>}/>
                            <Route path={`/${farm}/mobile/history`} exact
                                   render={props => <RFIDHistory {...props}/>}/>
                            <Route render={props => <MobileRFIDScanner {...props}/>}/>
                        </Switch>
                    </CSSTransition>
                </TransitionGroup>
                {
                    !showFullScreen &&
                    <div className="no-fullscreen">
                        <span className="fa-stack">
                            <i className="far fa-expand fa-stack-1x"/>
                            <i className="far fa-ban fa-stack-2x"/>
                        </span>
                        <div>{t("rfidApp.fullScreenRequired")}</div>
                        <Button buttonColor={"success"} icon={<i className="far fa-expand"/>}
                                onClick={this.onEnableFullScreenClick} type={"button"}>
                            {t("rfidApp.turnFullScreenOn")}
                        </Button>
                    </div>
                }
            </>
        );
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(RfidScannerMainView);
