import React, {Component} from 'react';
import PropTypes from 'prop-types';
import "./_data-input.scss"

class BigDataInput extends Component {

    state = {
        value: 0
    };

    getValue(place) {
        const {columns, input: {value}} = this.props;
        let val = (value + "").padStart(columns, "0");
        return +val[place];
    }

    setValue(place, number) {
        const {columns, input: {value, onChange}} = this.props;
        let val = (value + "").padStart(columns, "0").split("");
        val[place] = number + "";
        onChange(+val.join(""));
    }

    incrementValue(place) {
        let val = this.getValue(place) + 1;
        if (val === 10) val = 0;
        this.setValue(place, val);
    }

    decrementValue(place) {
        let val = this.getValue(place) - 1;
        if (val === -1) val = 9;
        this.setValue(place, val);
    }

    renderColumns() {
        const {columns} = this.props;
        let array = [];
        for (let i = 0; i < columns; i++) {
            array.push(
                <div className="button-column" style={{width: `calc(100% / ${columns} - 1rem)`}}>
                    <div className="button-container">
                        <div className="data-input-button">
                            <div>
                                <button type={"button"} onClick={() => this.incrementValue(i)}>
                                    <i className="far fa-plus"/>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="value-container">{this.getValue(i)}</div>
                    <div className="button-container">
                        <div className="data-input-button">
                            <div>
                                <button type={"button"} onClick={() => this.decrementValue(i)}>
                                    <i className="far fa-minus"/>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return array;
    }

    render() {
        const {label} = this.props;
        return (
            <div className="data-input-container">
                <label>{label}</label>
                <div className="data-input big">
                    {this.renderColumns()}
                </div>
            </div>
        );
    }
}

BigDataInput.propTypes = {
    columns: PropTypes.number,
    label: PropTypes.string
};

BigDataInput.defaultProps = {
    columns: 4
};

export default BigDataInput;