import React, {Component} from 'react';
import {Col, Row} from "react-bootstrap";
import PropTypes from 'prop-types';
import "./_data-input.scss";
import moment from "moment";

const changeRate = {
    SLOW: 200,
    MEDIUM: 100,
    FAST: 50
}

class DataInput extends Component {

    holdStart = null;
    timeout = null;
    type = null;

    changeValue(value) {
        const {input: {onChange}, date} = this.props;
        if (date) {
            onChange(value);
        } else {
            onChange(+(value.toFixed(1)));
        }
    }

    increaseValue = () => {
        const {input: {value}, step, max, date} = this.props;
        if (date) {
            let val = moment(value).add(step, "day");
            if (val.toDate().getTime() > max) {
                val = moment(max);
            }
            this.changeValue(val.format(moment.HTML5_FMT.DATE));
            return;
        }
        let val = value + step;
        if (val > max) {
            val = max;
        }
        this.changeValue(val);
    };

    decreaseValue = () => {
        const {input: {value}, step, min, date} = this.props;
        if (date) {
            let val = moment(value).subtract(step, "day");
            if (val.toDate().getTime() < min) {
                val = moment(min);
            }
            this.changeValue(val.format(moment.HTML5_FMT.DATE));
            return;
        }
        let val = value - step;
        if (val < min) {
            val = min;
        }
        this.changeValue(val);
    };

    getTimoutTime() {
        let currentTime = new Date().getTime();
        // jezeli pierwsze 2 sekund
        if (currentTime - this.holdStart < 1000 * 2) return changeRate.SLOW;
        // jezeli miedzy 2 a 4 sekund
        if (currentTime - this.holdStart < 1000 * 4) return changeRate.MEDIUM;
        // po 10 sekundach
        return changeRate.FAST;
    }

    startTimeout() {
        this.timeout = setTimeout(() => {
            if (this.type === "+") {
                this.increaseValue();
            } else {
                this.decreaseValue();
            }
            this.startTimeout();
        }, this.getTimoutTime());
    }

    onTouchStart() {
        this.holdStart = new Date().getTime();
        this.startTimeout();
    }

    onTouchEnd = () => {
        this.holdStart = null;
        this.type = null;
        clearTimeout(this.timeout);
    }

    onIncrementTouchStart = () => {
        this.type = "+";
        this.onTouchStart();
    }

    onDecrementTouchStart = () => {
        this.type = "-";
        this.onTouchStart();
    }

    disableContextMenu = e => {
        e.preventDefault();
        return false;
    }

    disableMaxValue() {
        const {input: {value}, max, date} = this.props;
        if (date) {
            return moment(max).isSame(value, "day");
        }
        return value === max;
    }

    render() {
        const {label} = this.props;
        const {input: {value}, min, step, valueFormatter, date} = this.props;
        return (
            <Row className="data-input">
                <Col xs={3} className="label-container">
                    {label}:
                </Col>
                <Col xs={3} className="button-container">
                    <div className="data-input-button">
                        <div>
                            <button onClick={this.decreaseValue} disabled={value === min} type={"button"}
                                    onTouchStart={this.onDecrementTouchStart} onTouchEnd={this.onTouchEnd}
                                    onContextMenu={this.disableContextMenu}>
                                <i className="far fa-minus"/>
                            </button>
                        </div>
                    </div>
                </Col>
                <Col xs={3} className="value-container">
                    <span>{valueFormatter ? valueFormatter(value) : date ? moment(value).format("DD.MM.YYYY") : value.toFixed(step < 1 ? 1 : 0)}</span>
                </Col>
                <Col xs={3} className="button-container">
                    <div className="data-input-button">
                        <div>
                            <button onClick={this.increaseValue} disabled={this.disableMaxValue()} type={"button"}
                                    onTouchStart={this.onIncrementTouchStart} onTouchEnd={this.onTouchEnd}
                                    onContextMenu={this.disableContextMenu}>
                                <i className="far fa-plus"/>
                            </button>
                        </div>
                    </div>
                </Col>
            </Row>
        );
    }
}

DataInput.propTypes = {
    label: PropTypes.string.isRequired,
    step: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    valueFormatter: PropTypes.func,
    date: PropTypes.bool,
};

DataInput.defaultProps = {
    step: 1,
    min: 0,
    max: 100
};

export default DataInput;