import React, {Component} from 'react';
import {connect} from 'react-redux';
import RfidHeader from "../rfid-header/RFIDHeader";
import "./_animal-chooser.scss"
import Carousel from "../carousel/Carousel";
import _ from "lodash";
import CarouselItem from "../carousel/CarouselItem";
import {Link} from "react-router-dom";
import {compose} from "redux";
import {withTranslation} from "react-i18next";

function mapStateToProps(state) {
    return {
        farm: state.location.farm,
        medicines: state.dictionary.medicine
    };
}

class MedicineChooser extends Component {

    state = {
        page: 0,
        medicines: MedicineChooser.getMedicines(this.props)
    };

    static getMedicines(props) {
        let array = [];
        const {medicines, location: {state}} = props;
        let meds = medicines.slice(0);
        if (state && state.medicineType !== undefined) {
            meds = meds.filter(item => item.WData.Type === state.medicineType);
        }
        for (let i = 0; i < meds.length; i += 9) {
            array.push(meds.slice(i, i + 9));
        }
        return array;
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            medicines: MedicineChooser.getMedicines(nextProps)
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return !_.isEqual(this.state, nextState);
    }

    componentDidMount() {
        const {location: {state}, history, farm} = this.props;
        if (!state) {
            history.push(`/${farm}/mobile`)
        }
    }

    changePage = page => {
        this.setState({
            page
        })
    };

    render() {
        const {location: {state}, farm, t} = this.props;
        if (!state) return null;
        const {breadcrumbs, eventType, status} = state;
        const {page, medicines} = this.state;
        return (
            <div className={"chooser"}>
                <RfidHeader breadcrumbs={breadcrumbs} back/>
                <Carousel page={page} onPageChange={this.changePage} title={t("rfidApp.chooseMedicine") + ":"}>
                    {
                        medicines.map((meds, key) =>
                            <CarouselItem key={key}>
                                <ul>
                                    {
                                        meds.map((med, key2) =>
                                            <li key={key2}>
                                                <Link to={{
                                                    pathname: `/${farm}/mobile/events/${eventType}/${status}`,
                                                    state: {
                                                        ...state,
                                                        medicine: med
                                                    }
                                                }} className={"animal-item"}>
                                                    <div>{med.WData.Name}</div>
                                                    <div className="color"
                                                         style={{background: med.WData.Color || "#FFFFFF"}}/>
                                                </Link>
                                            </li>
                                        )
                                    }
                                </ul>
                            </CarouselItem>
                        )
                    }
                </Carousel>
            </div>
        );
    }
}

export default compose(
    connect(mapStateToProps),
    withTranslation()
)(MedicineChooser);