import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {CSSTransition} from "react-transition-group";

class CarouselItem extends Component {

    render() {
        const {children, show} = this.props;
        return (
            <CSSTransition in={show} unmountOnExit mountOnEnter timeout={200} appear>
                <li>
                    {children}
                </li>
            </CSSTransition>
        );
    }
}

CarouselItem.propTypes = {
    children: PropTypes.node,
    page: PropTypes.number,
    show: PropTypes.bool,
};

export default CarouselItem;