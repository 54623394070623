import React, {Component} from 'react';
import {connect} from 'react-redux';
import RfidHeader from "../rfid-header/RFIDHeader";
import {Col, Row} from "react-bootstrap";
import Button from "../../../components/basics/button/Button";
import "./_animal-chooser.scss"
import buildingsDB from "../../../database/buildingsDB";
import InfoBox from "../../../components/basics/info-box/InfoBox";
import Carousel from "../carousel/Carousel";
import CarouselItem from "../carousel/CarouselItem";
import Keyboard from "../keyboard/Keyboard";
import {Link} from "react-router-dom";
import Drawer from "../drawer/Drawer";
import {compose} from "redux";
import {withTranslation} from "react-i18next";

function mapStateToProps(state) {
    return {
        farm: state.location.farm
    };
}

class StandsChooser extends Component {

    state = {
        showSearch: false,
        stands: [],
        filteredStands: [],
        page: 0
    };

    search = React.createRef();

    constructor(props) {
        super(props);

        const {match: {params: {CID}}} = this.props;
        let tree = buildingsDB.getTreeByLocationID(CID);
        let chamber = tree.chamber;
        this.state = {
            ...this.state,
            stands: chamber.Boxes,
            filteredStands: chamber.Boxes
        }
    }

    componentDidMount() {
        const {location: {state}, history, farm} = this.props;
        if (!state) {
            history.push(`/${farm}/mobile`)
        }
    }

    onPageChange = page => {
        this.setState({
            page
        })
    };

    renderCarousel() {
        const {filteredStands, page} = this.state;
        const {farm, location: {state}, t} = this.props;
        let slicedStands = [];
        for (let i = 0; i < filteredStands.length; i += 9) {
            slicedStands.push(filteredStands.slice(i, i + 9));
        }
        if (slicedStands.length === 0) return <InfoBox boxColor={"warning"}>Brak stanowisk</InfoBox>;
        return (
            <Carousel page={page} onPageChange={this.onPageChange} title={t("rfidApp.chooseStand") + ":"}>
                {
                    slicedStands.map((stands, key) =>
                        <CarouselItem key={key}>
                            <ul>
                                {
                                    stands.map((stand, key2) =>
                                        <li key={key2}>
                                            <Link className={"animal-item"} to={{
                                                pathname: state.locationNextPath || `/${farm}/mobile/events/${state.eventType}/${state.status}`,
                                                state: {
                                                    ...state,
                                                    stand
                                                }
                                            }}>
                                                <div>{stand.BoxesName}</div>
                                            </Link>
                                        </li>
                                    )
                                }
                            </ul>
                        </CarouselItem>
                    )
                }
            </Carousel>
        )
    }

    onSearchClick = () => {
        this.setState({
            showSearch: true
        })
    };

    hideSearch = () => {
        this.setState({
            showSearch: false
        })
    };

    onKeyboardChange = search => {
        let stands = this.state.stands.slice(0);
        if (search) {
            stands = stands.filter(item => item.BoxesName.includes(search));
        }
        this.setState({
            filteredStands: stands,
            page: 0
        })
    };

    render() {
        const {location: {state}} = this.props;
        if (!state) return null;
        const {breadcrumbs} = state;
        const {showSearch} = this.state;
        console.log(this.state);
        return (
            <div className={`chooser`}>
                <div className={`h-100 d-flex flex-column ${showSearch ? " no-pointer-events" : ""}`}>
                    <RfidHeader breadcrumbs={breadcrumbs} back/>
                    <Row className="button-container">
                        <Col xs={2}>
                            <Button buttonStyle={"text"} onClick={this.onSearchClick}>
                                <span className="fa-stack">
                                    <i className="far fa-circle fa-stack-2x"/>
                                    <i className="far fa-search fa-stack-1x"/>
                                </span>
                            </Button>
                        </Col>
                    </Row>
                    {this.renderCarousel()}
                </div>
                <Drawer onCloseClick={this.hideSearch} show={showSearch}>
                    <Keyboard onChange={this.onKeyboardChange}/>
                </Drawer>
            </div>
        );
    }
}

export default compose(
    connect(mapStateToProps),
    withTranslation()
)(StandsChooser);