import {SubmissionError} from "redux-form";
import groupsDB from "../../../database/groupsDB";

export function submit(values, dispatch, props) {
    let errors = validate(values, props);
    if (errors._error) {
        throw new SubmissionError(errors);
    }
    const {farm, location: {state}} = props;
    const {eventType, status, groupsNextPath} = state;
    const {GrNo1} = values;
    props.history.push({
        pathname: groupsNextPath || `/${farm}/mobile/events/${eventType}/${status}`,
        state: {
            ...state,
            groupNumber: GrNo1,
            group: null
        }
    })
}

export function validate(values, props) {
    const errors = {};

    if (values.GrNo1 === 0) {
        errors._error = "Numer grupy musi być większy od 1";
    } else {
        if (groupsDB.checkIfGroupExistsOnFarm(values.GrNo1 + "", props.farm)) {
            errors._error = "Podany numer grupy już istnieje";
        }
    }

    return errors;
}