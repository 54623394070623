import {SubmissionError} from "redux-form";
import animalsDB from "../../../database/animalsDB";

export function submit(values, dispatch, props) {
    let errors = validate(values, props);
    if (errors._error) {
        throw new SubmissionError(errors);
    }
    const {farm, location: {state}} = props;
    const {eventType, status} = state;
    const {AnmNo1} = values;
    props.history.push({
        pathname: `/${farm}/mobile/events/${eventType}/${status}`,
        state: {
            ...state,
            animalNumber: AnmNo1
        }
    })
}

export function validate(values, props) {
    const errors = {};

    if (values.GrNo1 === 0) {
        errors._error = "Numer zwierzęcia musi być większy od 1";
    } else {
        if (animalsDB.checkIfAnimalExistOnFarm(values.AnmNo1 + "", props.farm)) {
            errors._error = "Podany numer zwierzęcia już istnieje";
        }
    }

    return errors;
}